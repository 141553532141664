import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Skeleton,
} from "@mui/material";
import { fetchPlans } from '../redux/plan/planSlice'; // import the action

const PricingPlans = () => {
  const dispatch = useDispatch();
  const { plans, status, error } = useSelector((state) => state.pricing);

  useEffect(() => {
    dispatch(fetchPlans());
  }, [dispatch]);

  // Show skeleton loader if status is 'loading'
  if (status === "loading") {
    return (
      <Box
        sx={{
          padding: "32px",
          textAlign: "center",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{ marginBottom: "16px", letterSpacing: "0.05em" }}
        >
          UNLEASH YOUR POTENTIAL
        </Typography>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", marginBottom: "8px", lineHeight: 1.2 }}
        >
          Choose the Perfect Plan for Your Goals
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginBottom: "32px", maxWidth: "800px", margin: "0 auto" }}
        >
          Our plans are carefully crafted to provide the right balance of features
          and flexibility to match your specific needs. Whether you're starting
          small or scaling up, we've got you covered.
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "12px",
                  boxShadow: "none",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="80%" height={30} />
                  <Skeleton variant="text" width="60%" height={30} />
                  <Skeleton variant="rectangular" width="100%" height={200} sx={{ marginTop: "16px" }} />
                </CardContent>
                <Skeleton variant="rectangular" width="100%" height={50} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: "32px",
        textAlign: "center",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h6"
        color="text.secondary"
        sx={{ marginBottom: "16px", letterSpacing: "0.05em" }}
      >
        UNLEASH YOUR POTENTIAL
      </Typography>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "8px", lineHeight: 1.2 }}
      >
        Choose the Perfect Plan for Your Goals
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ marginBottom: "32px", maxWidth: "800px", margin: "0 auto" }}
      >
        Our plans are carefully crafted to provide the right balance of features
        and flexibility to match your specific needs. Whether you're starting
        small or scaling up, we've got you covered.
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                border: "1px solid #ddd",
                borderRadius: "12px",
                boxShadow: "none",
                "&:hover": { boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#4CAF50",
                    marginBottom: "8px",
                  }}
                >
                  ${plan.price}
                  {/* <Typography
                    variant="body2"
                    component="span"
                    sx={{ color: "text.secondary", marginLeft: "4px" }}
                  >
                    /mo
                  </Typography> */}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ marginTop: "8px", fontWeight: "bold" }}
                >
                  {plan.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: "8px", marginBottom: "16px" }}
                >
                  {plan.admin_user ? "Ideal for admins with full access" : "Start for free with no commitments"}
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    padding: "0",
                    margin: "0",
                    listStyle: "none",
                    textAlign: "left",
                    color: "text.secondary",
                    fontSize: "14px",
                    marginBottom: "16px",
                  }}
                >
                  <Typography component="li" sx={{ marginBottom: "8px", display: "flex" }}>
                    <span
                      style={{
                        color: "#4CAF50",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      ✓
                    </span>
                    {plan.allocated_credits} chat credits
                  </Typography>
                  <Typography component="li" sx={{ marginBottom: "8px", display: "flex" }}>
                    <span
                      style={{
                        color: "#4CAF50",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      ✓
                    </span>
                    Upload up to {plan.pdf_upload_limit} PDF files
                  </Typography>
                  <Typography component="li" sx={{ marginBottom: "8px", display: "flex" }}>
                    <span
                      style={{
                        color: "#4CAF50",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      ✓
                    </span>
                    Max file size: {plan.max_file_size / 1024 / 1024} MB
                  </Typography>
                  <Typography component="li" sx={{ marginBottom: "8px", display: "flex" }}>
                    <span
                      style={{
                        color: "#4CAF50",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      ✓
                    </span>
                    Max {plan.max_page_limit} pages per PDF
                  </Typography>
                </Box>
              </CardContent>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  marginTop: "16px",
                  fontWeight: "bold",
                  borderRadius: "0 0 12px 12px",
                }}
              >
                {plan.price === 0 ? "Start Free" : "Buy Now"}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingPlans;
