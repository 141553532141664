import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,ListItem,
  Avatar,
  IconButton,
  Paper,Skeleton,
  CircularProgress,
  Divider,
} from '@mui/material';
// import {
//   ArrowForward,
//   ChatBubbleOutline,
//   AudioFile,
//   YouTube,
//   Language,
//   Add as AddIcon,
//   Forum as ForumIcon,
//   Work as WorkIcon,
//   Store as StoreIcon,
//   RssFeed as FeedIcon,
//   AccountTree as WorkflowIcon,
//   AutoFixHigh as AutomationIcon,
//   Search as SearchIcon,
//   Star as UpgradeIcon,
// } from '@mui/icons-material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { sendNecMessage, resetNecChat } from '../redux/necChat/necChatSlice';
import ReactMarkdown from 'react-markdown';

import { useTheme } from '@mui/material/styles';
const NecChatScreen = ({ wsdata }) => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true); // Track if it's the user's first visit

  const { response } = useSelector((state) => state.necChat);
  const storedUser = JSON.parse(localStorage.getItem('user'));
  // Scroll to the bottom of the chat when messages update
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(resetNecChat());
    };
  }, [dispatch]);

  // Update messages with WebSocket data
  useEffect(() => {
    if (wsdata?.data?.data?.chatOutputs) {
      setIsFirstVisit(false);
      const formattedMessages = wsdata.data.data.chatOutputs.map((output) => ({
        question: output.question,
        answer: output.final_answer?.answer || '',
        relevantImages: output.image_urls || [],
        sender: 'bot',
      }));
      setMessages(formattedMessages);
    }
  }, [wsdata]);

  // Add bot responses from Redux state
  useEffect(() => {
    if (response) {
      const botResponse = {
        question: response.data?.question || '',
        answer: response.data?.answer || '',
        relevantImages: response.data?.relevantImages || [],
        sender: 'bot',
      };
      setMessages((prev) => [...prev, botResponse]);
      setIsLoadingResponse(false);
    }
  }, [response]);

  // Handle user message send
  const handleSendMessage = () => {
    if (inputValue.trim()) {
      const userMessage = { text: inputValue, sender: 'user' };
      setMessages((prev) => [...prev, userMessage]);

      setIsLoadingResponse(true);
      dispatch(sendNecMessage({ message: inputValue, userId: storedUser?._id }));

      setInputValue('');
      setIsFirstVisit(false); // Hide welcome message after the first message
    }
  };
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good Morning';
    if (hour < 18) return 'Good Afternoon';
    return 'Good Evening';
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '16px',
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      {/* Welcome Section */}
      {isFirstVisit && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            textAlign: 'center',
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRadius: '8px',
            padding: '32px',
            boxShadow: 3,
          }}
        >
          {/* <Typography
            variant="h2"
            sx={{
              mb: 2,
              color: '#3b82f6',
              fontWeight: 'bold',
            }}
          >
            Hello, <span>{storedUser?.name?.split(" ")[0] || 'User'}</span>

          </Typography>
          <Typography variant="h5" sx={{ mb: 4, color: '#6b7280' }}>
            <span style={{
              mb: 2,
              color: '#3b82f6',
              fontWeight: 'bold',
            }}>{getGreeting()} ,</span>   How can I help you today?
          </Typography> */}

<Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              mb: 1,
              background: 'linear-gradient(90deg, #2563eb 0%, #60a5fa 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Hello, Dhananjay
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '1.5rem', md: '2rem' },
              fontWeight: 500,
              mb: 6,
              // color: isDarkMode ? '#60a5fa' : '#2563eb',
              color:'#60a5fa',
            }}
          >
            {getGreeting()}, How can I help you today?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              // borderColor: theme.palette.mode === 'dark' ? '#616161' : "", // Update border color for dark mode
              mt: 2,
              borderRadius: '20px',
              backgroundColor: theme.palette.background.paper,
              padding: '10px',
              boxShadow: 3,
              position: 'relative',
              width: '100%',
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Type your message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              sx={{
                borderRadius: '20px',
                marginLeft: 1,
                marginRight: 1,
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
              }}
              InputProps={{
                style: {
                  color: theme.palette.text.primary,
                },
              }}
            />

            <IconButton
              onClick={handleSendMessage}
              sx={{
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.getContrastText(theme.palette.primary.main),
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>



        </Box>
      )}

      {/* Chat Section */}
      {!isFirstVisit && (
        <Paper
          elevation={3}
          sx={{
            flex: 1,
            overflowY: 'auto',
            marginBottom: '16px',
            padding: '16px',
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: 3,
          }}
        >
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Messages List */}
            <Box sx={{ flex: 1, overflowY: 'auto', marginBottom: '8px' }}>
              {messages.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: msg.sender === 'user' ? 'row-reverse' : 'row',
                    marginBottom: '8px',
                    alignItems: 'center',
                  }}
                >
                  {/* User Message */}
                  {msg.sender === 'user' && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ bgcolor: '#3b82f6', marginRight: '8px' }}>
                        {/* Extracting the first letter of the user's name */}
                        {storedUser?.name ? storedUser?.name.charAt(0).toUpperCase() : 'U'}
                      </Avatar>
                      <Box
                        sx={{
                          backgroundColor: '#e0f7fa',
                          padding: '8px 12px',
                          borderRadius: '16px',
                          color: '#006064',
                          maxWidth: '70%',
                          marginLeft: '8px',
                        }}
                      >
                        {msg.text}
                      </Box>
                    </Box>
                  )}

                  {/* Bot Message */}
                  {msg.sender === 'bot' && (
                    // <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    //   <Avatar sx={{ bgcolor: '#3b82f6', marginRight: '8px' }}>
                    //     <AccountCircleIcon />
                    //   </Avatar>
                    //   <Box
                    //     sx={{
                    //       backgroundColor: '#f0f4f8',
                    //       padding: '8px 12px',
                    //       borderRadius: '16px',
                    //       color: '#333',
                    //       maxWidth: '70%',
                    //       marginRight: '8px',
                    //     }}
                    //   >
                    //     <ReactMarkdown>{msg.answer}</ReactMarkdown>
                    //   </Box>
                    // </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ bgcolor: '#3b82f6', marginRight: '8px' }}>
                        {/* Extracting the first letter of the user's name */}
                        {'AI'}
                      </Avatar>
                      <Box
                        sx={{
                          backgroundColor: '#f0f4f8',
                          padding: '8px 12px',
                          borderRadius: '16px',
                          color: '#333',
                          maxWidth: '70%',
                          marginRight: '8px',
                        }}
                      >
                        <ReactMarkdown>{msg.answer}</ReactMarkdown>
                      </Box>
                    </Box>

                  )}
                </Box>
              ))}

              {/* Loading State */}
              {isLoadingResponse && (
                <ListItem alignItems="flex-start">
                <Box width="100%">
                  <Skeleton variant="text" height={20} width="40%" />
                  <Skeleton variant="text" height={20} width="60%" />
                  <Skeleton variant="rectangular" height={118} width="100%" />
                </Box>
              </ListItem>
                // <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                //   <Skeleton
                //     variant="text"
                //     animation="wave"
                //     height={20}
                //     width="40%"
                //   />
                //   <Skeleton
                //     variant="text"
                //     animation="wave"
                //     height={20}
                //     width="60%"
                //   />
                //   <Skeleton
                //     variant="rectangular"
                //     animation="wave"
                //     height={118}
                //     width="100%"
                //   />

                // </Box>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                // borderColor: theme.palette.mode === 'dark' ? '#616161' : "", // Update border color for dark mode
                mt: 2,
                borderRadius: '20px',
                backgroundColor: theme.palette.background.paper,
                padding: '10px',
                boxShadow: 3,
                position: 'relative',
                width: '100%',
              }}
            >
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Type your message..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                sx={{
                  borderRadius: '20px',
                  marginLeft: 1,
                  marginRight: 1,
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.text.primary,
                }}
                InputProps={{
                  style: {
                    color: theme.palette.text.primary,
                  },
                }}
              />

              <IconButton
                onClick={handleSendMessage}
                sx={{
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.getContrastText(theme.palette.primary.main),
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>

            {/* Bottom Message Input */}
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                padding: '8px 16px',
                borderRadius: '8px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <TextField
                placeholder="Type your message..."
                variant="outlined"
                fullWidth
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                sx={{ marginRight: '8px' }}
              />
              <IconButton
                onClick={handleSendMessage}
                sx={{
                  backgroundColor: '#3b82f6',
                  color: 'white',
                  '&:hover': { backgroundColor: '#005bb5' },
                }}
              >
                <SendIcon />
              </IconButton>
            </Box> */}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default NecChatScreen;
