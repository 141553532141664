import { configureStore } from '@reduxjs/toolkit';
import pdfReducer from './survey/pdfSlice'; 
import authReducer from './auth/authSlice';
import SurveychatReducer from './survey/Surveychatslice';
import signupReducer from './auth/signupSlice';
import UploadHistoryReducers from './survey/uploadHistorySlice';
import NechatReducers from './newchat/nechatSlice';
import generalChatSlice from './newchat/generalChatSlice';
import planSetAnalyzerSlice from './survey/planSetAnalyzerSlice';
import necChatSlice from './necChat/necChatSlice';
import planSlice from './plan/planSlice';
const store = configureStore({
  reducer: {
    signup: signupReducer,
    pdf: pdfReducer,
    auth: authReducer,
    surveychat:SurveychatReducer,
    uploadHistory:UploadHistoryReducers,
    newchat:NechatReducers,
    generalChat:generalChatSlice,
    planSetAnalyzer:planSetAnalyzerSlice,
    necChat:necChatSlice,
    pricing:planSlice

  },
});

export default store;
