import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const processNewChatPdf = createAsyncThunk(
    'pdf/processNewChatPdf',
    async ({ file, userId }, { rejectWithValue }) => {
      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('userId', userId);
      try {

        // `http://general-staging.framesense.ai/api/v1/pdf/process`,
        
        const response = await axios.post(
          `https://general.framesense.ai/api/v1/pdf/process`,
          // `http://general-staging.framesense.ai/api/v1/pdf/process`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-api-key': 'Wattmonk321',
              'x-user-id': userId,              },
          }
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );

const nechatSlice = createSlice({
  name: 'newchatpdf',
  initialState: {
    selectedNewChatFile: null,
    responseNewChatData: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedNewchatFile: (state, action) => {
      state.selectedNewChatFile = action?.payload;
      state.responseNewChatData = null; // Reset previous response
      state.error = null; // Reset previous errors
    },
    reset: (state) => {
      state.selectedNewChatFile = null;
      state.responseNewChatData = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processNewChatPdf.pending, (state) => {
        state.loading = true;
      })
      .addCase(processNewChatPdf.fulfilled, (state, action) => {
        state.loading = false;
        state.responseNewChatData = action?.payload;
      })
      .addCase(processNewChatPdf.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

export const { setSelectedNewchatFile, reset } = nechatSlice.actions;

export default nechatSlice.reducer;
