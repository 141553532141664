import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    IconButton,
    useTheme,
    Grid,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SkeletonLoader from './SkeletonLoader';
import ImageModal from './ImageModal';
import ReactMarkdown from 'react-markdown'; 
const ServeyChatHistoryScreen = ({ chatResposeData, conestiondata, setChatData, req_id }) => {
    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [isResponding, setIsResponding] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentMessageImages, setCurrentMessageImages] = useState([]);
    const messagesEndRef = useRef(null);
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        if (chatResposeData) {
            setMessages(chatResposeData);
        }
    }, [chatResposeData]);

    useEffect(() => {
        if (!conestiondata?.websocket_url) {
            console.error('WebSocket URL is not provided');
            setLoading(false);
            return;
        }

        const newSocket = new WebSocket(conestiondata.websocket_url);
        setSocket(newSocket);

        newSocket.onopen = () => {
            console.log('WebSocket connection established.');
            setLoading(false);
        };

        newSocket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.data) {
                    // const parsedMessage = JSON.parse(data.message);
                    const { category_confidence, question, image_urls, final_answer } = data.data;

                    const botMessage = {
                        sender: 'bot',
                        category_confidence,
                        question,
                        image_urls,
                        final_answer,
                    };

                    setMessages((prevMessages) => [...prevMessages, botMessage]);
                    setChatData((prevChatData = []) => [...prevChatData, botMessage]);

                }
            } catch (error) {
                console.error('Error parsing message:', error);
                setLoading(false);
            } finally {
                setIsResponding(false);
            }
        };

        newSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setLoading(false);
        };

        newSocket.onclose = () => {
            console.log('WebSocket connection closed.');
            setLoading(false);
        };

        return () => {
            if (newSocket.readyState === WebSocket.OPEN) {
                newSocket.close();
            }
        };
    }, [conestiondata]);

    useEffect(() => {
        if (conestiondata?.chatOutputs?.length > 0) {
            const chatMessages = conestiondata.chatOutputs.map(output => ({
                sender: 'bot',
                category_confidence: 'N/A',
                question: output.question,
                image_urls: output.imageUrls,
                final_answer: {
                    answer: output.openAIAnswer,
                    confidence_score: output.confidenceScore
                }
            }));
            setMessages((prevMessages) => [...prevMessages, ...chatMessages]);
        }
    }, [conestiondata]);

    const handleSendMessage = () => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (inputValue.trim() !== '') {
            const userMessage = { text: inputValue, sender: 'user' };
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            setInputValue('');

            if (socket && socket.readyState === WebSocket.OPEN) {
                const messageBody = { "user_id": storedUser?._id, request_id: req_id, question: inputValue };

                socket.send(JSON.stringify(messageBody));
            }

            setIsResponding(true);
        }
    };

    const handleImageClick = (images, index) => {
        setCurrentMessageImages(images);
        setCurrentImageIndex(index);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentImageIndex(0);
        setCurrentMessageImages([]);
    };

    const renderBotMessage = (message) => (
        <Box sx={{ mb: 2 }}>
            <Typography variant="body1" gutterBottom>
                <strong>Q:</strong> {message.question}
            </Typography>

            {message.image_urls && message.image_urls.length > 0 && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {message.image_urls.map((url, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <img
                                src={url}
                                alt={`Image ${index + 1}`}
                                style={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
                                onClick={() => handleImageClick(message.image_urls, index)}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            {message.final_answer && (
                <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>Answer:</strong><ReactMarkdown>{message.final_answer.answer}</ReactMarkdown> 
                    {/* (Confidence: {message.final_answer.confidence_score}) */}
                </Typography>
            )}
        </Box>
    );

    return (
        <Box sx={{ paddingTop: 2, height: '80vh', display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.background.default, width: '100%' }}>
            <Paper elevation={3} sx={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', borderRadius: 2, backgroundColor: theme.palette.background.paper, width: '100%' }}>
                <List>
                    {messages.map((msg, index) => (
                        <ListItem alignItems="flex-start" key={index}>
                            {msg.sender === 'user' ? (
                                <ListItemText primary={<Typography variant="body1" fontWeight="bold" color={theme.palette.text.primary}>{msg.text}</Typography>} />
                            ) : (
                                <ListItemText primary={renderBotMessage(msg)} />
                            )}
                        </ListItem>
                    ))}

                    {isResponding && (
                        <ListItem alignItems="flex-start">
                            <ListItemText primary={<SkeletonLoader />} />
                        </ListItem>
                    )}
                </List>
                <div ref={messagesEndRef} />
            </Paper>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, borderRadius: '20px', backgroundColor: theme.palette.background.paper, padding: '2px', boxShadow: 3, position: 'relative', width: '100%', border: '1px solid', borderColor: theme.palette.divider }}>
                <TextField variant="outlined" fullWidth placeholder="Type your message..." value={inputValue} onChange={(e) => setInputValue(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()} sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }} />
                <IconButton onClick={handleSendMessage}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>

            {/* Image Modal */}
            <ImageModal open={openModal} onClose={handleCloseModal} images={currentMessageImages} initialIndex={currentImageIndex} />
        </Box>
    );
};

export default ServeyChatHistoryScreen;
