import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { processPlansetPdf, setSelectedPlansetFile, resetPlansetPdfData } from '../../redux/survey/planSetAnalyzerSlice';
import PlansetDataDisplay from '../../components/PlansetDataDisplay';
import SkeletonLoader from '../../components/SkeletonLoader';
import Upload from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';

const PlanSetAnalyzer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { selectedPlanSetFile, plansetPdfresponseData, loading, error } = useSelector((state) => state.planSetAnalyzer || {});
    const [pdfFile, setPdfFile] = useState(null);
    const [askQuestionsOpen, setAskQuestionsOpen] = useState(false);
    const [isChatScreenOpen, setIsChatScreenOpen] = useState(false);

    const handleNewChat = () => {
        navigate('/');
    };

    const handleToggleChat = () => {
        setIsChatScreenOpen(!isChatScreenOpen);
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (file) {
            if (file.type === 'application/pdf') {
                setPdfFile(file);
                dispatch(setSelectedPlansetFile(file)); // Update Redux state
                const userId = storedUser?._id?.slice(-8); // Replace with actual user ID
                dispatch(processPlansetPdf({ file: file, userId })); // Automatically upload the file
            } else {
                alert('Please select a PDF file.');
            }
        }
    }, [dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        multiple: false,
    });
    // Reset state on component unmount
    useEffect(() => {
        return () => {
            dispatch(resetPlansetPdfData());
        };
    }, [dispatch]);

    return (
        <Box sx={{ position: 'relative', textAlign: 'center', padding: 4 }}>
            <Typography
                variant="h6"
                sx={{
                    position: 'absolute',
                    top: "10px",
                    left: "2px", // Adjust this value to position text correctly
                    fontWeight: 'bold',
                }}
            >
                Template / Planset Analyzer
            </Typography>

            {!loading && !plansetPdfresponseData && (
                <Paper
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed',
                        borderColor: isDragActive
                            ? theme.palette.primary.main
                            : theme.palette.mode === 'dark'
                                ? '#616161'
                                : '#E0E0E0',
                                marginTop:10,
                        borderRadius: 4,
                        padding: 4,
                        textAlign: 'center',
                        backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F9FAFB',
                        transition: 'border-color 0.3s ease-in-out',
                    }}
                >
                    <input {...getInputProps()} />
                    <Box
                        sx={{
                            width: 96,
                            height: 96,
                            backgroundColor: theme.palette.mode === 'dark' ? '#616161' : '#E0E0E0',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginX: 'auto',
                            marginBottom: 2,
                        }}
                    >
                        <Upload sx={{ fontSize: '48px', color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#9E9E9E' }} />
                    </Box>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', marginBottom: 1, color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937' }}
                    >
                        {isDragActive ? 'Drop the file here' : 'Drag and drop a file or click to upload'}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}
                    >
                        {selectedPlanSetFile ? `Selected file: ${selectedPlanSetFile.name}` : 'Upload a PDF file to start analyzing your planset data'}
                    </Typography>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
                            {error}
                        </Typography>
                    )}
                    {/* <Button
                        variant="contained"
                        sx={{
                            marginTop: 2,
                            backgroundColor: theme.palette.mode === 'dark' ? '#1976D2' : '#3B82F6',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: theme.palette.mode === 'dark' ? '#2196F3' : '#2563EB',
                            },
                        }}
                    >
                        Browse Files
                    </Button> */}
                </Paper>
            )}

            {loading && <SkeletonLoader />}

            {plansetPdfresponseData && (
                <>
                    <PlansetDataDisplay
                        selectedFile={selectedPlanSetFile}
                        wsdata={plansetPdfresponseData}
                        askQuestionsOpen={askQuestionsOpen}
                        setAskQuestionsOpen={setAskQuestionsOpen}
                        isChatScreenOpen={isChatScreenOpen}
                    />
                </>
            )}

            {plansetPdfresponseData && askQuestionsOpen && (
                <Button
                    sx={{
                        position: 'fixed',
                        top: '250px',
                        right: '0px',
                        zIndex: 1000,
                        backgroundColor: '#3f51b5',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#303f9f',
                        },
                    }}
                    onClick={handleToggleChat}
                >
                    {isChatScreenOpen ? 'Close Chat' : 'Open Chat'}
                </Button>
            )}
        </Box>
    );
};

export default PlanSetAnalyzer;
