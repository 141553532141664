import React, { useState, useContext } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Link,
  Card,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import ForgotPasswordModal from '../components/ForgotPasswordModal';
import { validateEmail, validatePassword } from '../utils/validation';
import { loginUser, loginwithGoogle, resetError } from '../redux/auth/authSlice';
import { GoogleLogin } from '@react-oauth/google';
// import { PublicClientApplication } from '@azure/msal-browser';

// const msalInstance = new PublicClientApplication({
//   auth: {
//     clientId: 'YOUR_MICROSOFT_CLIENT_ID', // Replace with your Microsoft App Client ID
//     redirectUri: window.location.origin,
//   },
// });

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });
  const [openModal, setOpenModal] = useState(false);
  const [validationError, setValidationError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
      setFormErrors({
        ...formErrors,
        email: validateEmail(value) ? '' : 'Please enter a valid email address.',
      });
    } else if (name === 'password') {
      setFormErrors({
        ...formErrors,
        password: validatePassword(value) ? '' : 'Password must be at least 6 characters long.',
      });
    }
  };

  React.useEffect(() => {
    if (user) {
      login(user);
      navigate('/survey-analyzer');
    }
  }, [user, navigate]);

  React.useEffect(() => {
    if (error) {
      setValidationError(error.message || 'Failed to login');
    }
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationError('');

    const emailError = validateEmail(formData.email) ? '' : 'Please enter a valid email address.';
    const passwordError = validatePassword(formData.password) ? '' : 'Password must be at least 6 characters long.';

    if (emailError || passwordError) {
      setFormErrors({ email: emailError, password: passwordError });
      return;
    }

    try {
      await dispatch(loginUser({ email: formData.email, password: formData.password })).unwrap();
      login(user);
    } catch (err) {
      setValidationError('Invalid email or password. Please try again.');
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      if (response) {
        // Send the token to your backend for further authentication
        const credential = response.credential;
      dispatch(loginwithGoogle({token:credential})); // Pass the credential to your login function
      }
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };
  

  const handleGoogleFailure = (error) => {
    console.error('Google Login Failed:', error);
  };

  const handleMicrosoftLogin = async () => {
    try {
      // const response = await msalInstance.loginPopup();
      console.log('Microsoft Login Success:');
      // Send response data to your backend for verification and login
    } catch (error) {
      console.error('Microsoft Login Failed:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            padding: 4,
            borderRadius: '16px',
            boxShadow: 3,
          }}
        >
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Login
          </Typography>
          {validationError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {validationError}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name="password"
              value={formData.password}
              onChange={handleChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                padding: 1.5,
                borderRadius: 4,
                bgcolor: loading || !formData.password || !formData.email ? 'grey' : 'black',
                color: 'white',
              }}
              disabled={loading || !formData.password || !formData.email}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
            </Button>
          </Box>
          
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Link href="#" onClick={() => setOpenModal(true)}>
              Forgot Password?
            </Link>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              Don't have an account?
            </Typography>
            <Link href="/signup" variant="body2">
              Sign Up
            </Link>
          </Box>
          {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <GoogleLogin onSuccess={handleGoogleSuccess} onError={handleGoogleFailure} />
          </Box> */}
          {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleMicrosoftLogin}>
              Continue with Microsoft
            </Button>
          </Box> */}
        </Card>
      </Container>
      
      <ForgotPasswordModal open={openModal} handleClose={() => setOpenModal(false)} />
    </Box>
  );
};

export default Login;
