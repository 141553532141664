import React, { createContext, useState, useContext } from 'react';
const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [connections, setConnections] = useState({});
  const [connectionStatus, setConnectionStatus] = useState({});
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState({});
  const [statusMessage, setStatusMessage] = useState({});
  const [allDataLoaded, setAllDataLoaded] = useState({});
  const [askQuestionsOpen, setAskQuestionsOpen] = useState({});
  const [activeStep, setActiveStep] = useState({});
  const [categorizedData, setCategorizedData] = useState({});

  const steps = [
    'Connecting...',
    'Analyzing Images',
    'Generating Data',
    'Formulating Questions',
    'Preparing Results',
    'Process Complete',
  ];

  const stepMapping = {
    image_labeling: 1,
    embeddings: 2,
    questions: 3,
    result: 4,
  };

  const connectWebSocket = (id, url) => {
    if (connections[id]) return;

    setMessages((prev) => ({ ...prev, [id]: [] }));
    setCategorizedData((prev) => ({
      ...prev,
      [id]: {
        Electrical: [],
        SolarPanels: [],
        Measurements: [],
        Structural: [],
        General: [],
      },
    }));
    setLoading((prev) => ({ ...prev, [id]: true }));
    setStatusMessage((prev) => ({ ...prev, [id]: 'Connecting...' }));
    setActiveStep((prev) => ({ ...prev, [id]: 0 }));
    setAllDataLoaded((prev) => ({ ...prev, [id]: false }));
    setAskQuestionsOpen((prev) => ({ ...prev, [id]: false }));
    setConnectionStatus((prev) => ({ ...prev, [id]: { status: 'pending', loading: true } }));

    const ws = new WebSocket(url);
    setConnections((prev) => ({ ...prev, [id]: ws }));

    ws.onopen = () => {
      setStatusMessage((prev) => ({ ...prev, [id]: 'Connection established...' }));
      setConnectionStatus((prev) => ({ ...prev, [id]: { status: 'connected', loading: false } }));
      setActiveStep((prev) => ({ ...prev, [id]: 1 }));
      setLoading((prev) => ({ ...prev, [id]: false }));
      setAllDataLoaded((prev) => ({ ...prev, [id]: true }));
    };

    ws.onmessage = (event) => {
      const messageData = event.data;
      try {
        const parsedData = JSON.parse(messageData);
        const { status, step, message, data: payload } = parsedData;

        setMessages((prev) => ({
          ...prev,
          [id]: (prev[id] || []).concat(parsedData),
        }));

        if (status === 'processing' || status === 'completed') {
          const newStep = stepMapping[step];
          if (newStep !== undefined) {
            setActiveStep((prev) => ({ ...prev, [id]: newStep }));
            setStatusMessage((prev) => ({ ...prev, [id]: message }));
          }
        }

        if (status === 'result') {
          if(!askQuestionsOpen[id]){

            setAskQuestionsOpen((prev) => ({ ...prev, [id]: true }));
          }
          categorizeData(id, payload);
          setActiveStep((prev) => ({ ...prev, [id]: 4 }));
        }

        if (status === 'completed' && message === 'All processing completed') {
          setStatusMessage((prev) => ({ ...prev, [id]: message }));
          setLoading((prev) => ({ ...prev, [id]: false }));
          setAllDataLoaded((prev) => ({ ...prev, [id]: false }));
          setActiveStep((prev) => ({ ...prev, [id]: 5 }));
          setConnectionStatus((prev) => ({ ...prev, [id]: { status: 'closed', loading: false } }));
        }
      } catch (e) {
        console.warn('Non-JSON message:', messageData);
        setStatusMessage((prev) => ({ ...prev, [id]: messageData }));
      }
    };

    ws.onerror = () => {
      setStatusMessage((prev) => ({ ...prev, [id]: 'WebSocket error occurred.' }));
      setLoading((prev) => ({ ...prev, [id]: false }));
      setAllDataLoaded((prev) => ({ ...prev, [id]: false }));
      setConnectionStatus((prev) => ({ ...prev, [id]: { status: 'error', loading: false } }));
    };

    ws.onclose = () => {
      setAllDataLoaded((prev) => ({ ...prev, [id]: false }));
      setConnectionStatus((prev) => {
        const newStatus = { ...prev };
        delete newStatus[id];
        return newStatus;
      });
      setConnections((prev) => {
        const newConnections = { ...prev };
        delete newConnections[id];
        return newConnections;
      });
      setStatusMessage((prev) => ({ ...prev, [id]: 'WebSocket closed unexpectedly.' }));
    };
  };

  // Categorize data for each category, assign unique IDs
  const categorizeData = (id, data) => {
    const electricalCategories = [
      "Utility_Meter",
      "Electrical_Panel_1",
      "Electrical_Panel_2",
      "ServiceDisconnect",
      "Electrical_Labels"
    ];

    const category = electricalCategories.includes(data.category) ? "Electrical" : data.category;
    const uniqueId = `${category}-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;

    setCategorizedData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [category]: [...(prev[id][category] || []), { ...data, uniqueId }],
      },
    }));
  };

  // Handle edit action for a specific category and item based on uniqueId
  const editCategoryData = (id, category, uniqueId, newData) => {
    console.log("Function called with:", id, category, uniqueId, newData);
  
    setCategorizedData((prev) => {
      const currentCategoryData = prev[id]?.[category] || [];
      console.log("Current data:", currentCategoryData);
  
      const updatedCategoryData = currentCategoryData.map((item) =>
        item.uniqueId === uniqueId ? { ...item, ...newData } : item
      );
  
      // Direct return without further nested cloning
      const updatedData = {
        ...prev,
        [id]: {
          ...prev[id],
          [category]: updatedCategoryData,
        },
      };
  
      console.log("Updated data structure:", updatedData);
      return updatedData;
    });
  };
  
  
  

  // Reset data for a specific category
  const resetCategoryData = (id, category) => {
    setCategorizedData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [category]: [],
      },
    }));
  };

  const checkConnectionStatus = (id) => {
    return connectionStatus[id] || { status: 'none', loading: false };
  };

  return (
    <WebSocketContext.Provider value={{
      connectWebSocket,
      messages,
      loading,
      statusMessage,
      allDataLoaded,
      askQuestionsOpen,
      categorizedData,
      setCategorizedData,
      activeStep,
      steps,
      checkConnectionStatus,
      editCategoryData,
      resetCategoryData,
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
