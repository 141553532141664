import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import SurveyAnalyzer from './pages/SurveyAnalyzer';
import ProtectedRoutes from './router/ProtectedRoutes';
import PublicRoutes from './router/PublicRoutes';
import DashboardLayout from './layouts/DashboardLayout';
import AutomationTemplates from './pages/AutomationTemplates';
import NewChat from './pages/NewChat';
import PlanSetAnalyzer from './pages/templates/PlanSetAnalyzer';
import ThreadsPage from './pages/ThreadsPage';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NecChatScreen from './pages/NecChatScreen';
import PricingPlans from './pages/PricingPlans';


const App = () => {
  return (
    // <GoogleOAuthProvider clientId="915613059391-d7frl3m86q1v3rb81pd413itogk5940d.apps.googleusercontent.com">
    <GoogleOAuthProvider clientId="157947487285-ofebhmdnj8hrkbljv3lo5g3f7745g7qj.apps.googleusercontent.com">
      <WebSocketProvider>
        <Routes>
          {/* Public Routes */}
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </Route>

          {/* Protected Routes */}
          <Route element={<ProtectedRoutes />}>
            <Route element={<DashboardLayout />}>
              {/* Default Route */}
              <Route path="/" element={<Navigate to="/new-chat" replace />} />
              <Route path='/pricing' element={<PricingPlans/>} />
              <Route path="/survey-analyzer" element={<SurveyAnalyzer />} />
              <Route path="/threads/:id" element={<ThreadsPage />} />
              <Route path="/new-chat" element={<NewChat />} />
              <Route path="/nec-chat" element={<NecChatScreen/>} />
              <Route path='/planset-analyzer' element={<PlanSetAnalyzer />} />
              <Route path="/automations-template" element={<AutomationTemplates />} />
            </Route>
          </Route>

          {/* Fallback Route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </WebSocketProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
