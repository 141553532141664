import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppProvider } from '@toolpad/core/AppProvider';
import {
  Toolbar,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Chip,
} from '@mui/material';
import { logoutUser } from '../redux/auth/authSlice';
import { fetchsearchesTabs, editTab, deleteTab } from '../redux/survey/uploadHistorySlice';
import { AuthContext } from '../contexts/AuthContext';
import { createTheme } from '@mui/material/styles';
import { DashboardLayout as ToolpadDashboardLayout } from '@toolpad/core/DashboardLayout';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Upload from '@mui/icons-material/Upload';
import FolderIcon from '@mui/icons-material/Folder';
import BarChartIcon from '@mui/icons-material/BarChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
});

function CustomDrawer(props) {
  const { window } = props;
  const { user, logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchstabs } = useSelector((state) => state.uploadHistory);

  const [pathname, setPathname] = useState('/new-chat');
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTabId, setIsEditingTabId] = useState(null);
  const [editedTabName, setEditedTabName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [menuTabId, setMenuTabId] = useState(null);

  useEffect(() => {
    if (user?._id) {
      dispatch(fetchsearchesTabs(user._id));
    }
  }, [dispatch, user?._id]);

  useEffect(() => {
    localStorage.setItem('currentPath', pathname);
    if (isEditing) {
      setIsEditing(false);
      setIsEditingTabId(null);
      setEditedTabName('');
    }
  }, [pathname]);

  const handleNavigation = (path) => {
    setPathname(path);
    navigate(path);
  };

  const handleEditClick = (tabId, fileName) => {
    setIsEditing(true);
    setIsEditingTabId(tabId);
    setEditedTabName(fileName);
    handleMenuClose();
  };

  const handleMenuClick = (event, tab) => {
    setAnchorEl(event.currentTarget);
    setMenuTabId(tab._id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTabId(null);
  };

  const handleDeleteClick = (item) => {
    setDeleteDialogOpen(true);
    setItemToDelete(item);
    handleMenuClose();
  };


  const handleDelete = () => {
    dispatch(deleteTab(itemToDelete?._id)).then(() => {
      dispatch(fetchsearchesTabs(user?._id)); // Update list after delete
    });
    setDeleteDialogOpen(false);
  };

  const handleSave = () => {
    if (isEditingTabId && editedTabName.trim()) {
      dispatch(editTab({ searchTabId: isEditingTabId, fileName: editedTabName })).then(() => {
        dispatch(fetchsearchesTabs(user?._id)); // Update list after edit
      });
      setIsEditing(false);
      setIsEditingTabId(null);
      setEditedTabName('');
    }
  };

  const groupTabsByTime = (tabs) => {
    const today = new Date();
    const dayInMs = 24 * 60 * 60 * 1000;

    const todayTabs = [];
    const last7DaysTabs = [];
    const last30DaysTabs = [];
    const monthlyTabs = {};

    tabs.forEach((tab) => {
      const tabDate = new Date(tab.createdAt);
      const timeDiff = today - tabDate;

      if (timeDiff < dayInMs) {
        todayTabs.push(tab);
      } else if (timeDiff < 7 * dayInMs) {
        last7DaysTabs.push(tab);
      } else if (timeDiff < 30 * dayInMs) {
        last30DaysTabs.push(tab);
      } else {
        const monthYear = `${tabDate.getMonth() + 1}-${tabDate.getFullYear()}`;
        if (!monthlyTabs[monthYear]) {
          monthlyTabs[monthYear] = [];
        }
        monthlyTabs[monthYear].push(tab);
      }
    });

    return [
      { label: 'Today', tabs: todayTabs },
      { label: 'Previous 7 Days', tabs: last7DaysTabs },
      { label: 'Previous 30 Days', tabs: last30DaysTabs },
      { label: 'By Month', tabs: Object.entries(monthlyTabs).map(([month, tabs]) => ({ label: month, tabs })) },
    ];
  };

  const groupedTabs = groupTabsByTime(searchstabs);

  const navigation = [
    {
      segment: 'new-chat',
      title: 'New Chat',
      icon: <CommentOutlinedIcon />,
      onClick: () => handleNavigation('/new-chat'),
    },
    {
      segment: 'survey-analyzer',
      title: 'Survey Analyzer',
      icon: <Upload />,
      onClick: () => handleNavigation('/survey-analyzer'),
    },
    {
      segment: 'threads',
      title: 'Threads',
      icon: <FolderIcon />,
      action: <Chip label={searchstabs.length} color="primary" size="small" />,
      children: groupedTabs.map((group) => ({
        title: <span>{group.label}</span>,
        children: group.tabs.map((tab) => ({
          segment: `${tab._id}`,
          title: (
            <>
              {isEditing && isEditingTabId === tab._id ? (
                <TextField
                  value={editedTabName}
                  onChange={(e) => setEditedTabName(e.target.value)}
                  size="small"
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSave();
                  }}
                  autoFocus
                  disabled={!editedTabName.trim()}
                />
              ) : (
                <span>{tab.fileName}</span>
              )}
            </>
          ),
          icon: <BarChartIcon />,
          onClick: () => handleNavigation(`/${tab._id}`),
          action: (
            <>
              <IconButton onClick={(event) => handleMenuClick(event, tab)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && menuTabId === tab._id}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleEditClick(tab._id, tab.fileName)}>
                  <EditIcon sx={{ marginRight: 2 }} /> Rename
                </MenuItem>
                <MenuItem onClick={() => handleDeleteClick(tab)}>
                  <DeleteIcon sx={{ color: 'red', marginRight: 2 }} /> <span style={{ color: 'red' }}>Delete</span>
                </MenuItem>
              </Menu>
            </>
          ),
        })),
      })),
    },
    {
      segment: 'automations-template',
      title: 'Template',
      icon: <Upload />,
      onClick: () => handleNavigation('/automations-template'),
    },
  ];

  return (
    <AppProvider
      branding={{ title: 'FRAMESENSE' }}
      navigation={navigation}
      session={{ user }}
      router={{
        pathname,
        searchParams: new URLSearchParams(),
        navigate: (path) => handleNavigation(path),
      }}
      theme={demoTheme}
      window={window}
      authentication={{
        signOut: () => {
          logout();
          dispatch(logoutUser());
          navigate('/');
        },
      }}
    >
      <ToolpadDashboardLayout>
        <Outlet />
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this tab? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </ToolpadDashboardLayout>
    </AppProvider>
  );
}

CustomDrawer.propTypes = {
  window: PropTypes.func,
};

export default CustomDrawer;
