import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Chat from './ChatScreen';

import { fetchsearchesTabs } from '../redux/survey/uploadHistorySlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { processNewChatPdf, setSelectedNewchatFile, reset } from '../redux/newchat/nechatSlice';
import SkeletonLoader from '../components/SkeletonLoader';
import Upload from '@mui/icons-material/Upload';
import { useTheme } from '@mui/material/styles';

const NewChat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { selectedNewChatFile, responseNewChatData, loading, error } = useSelector((state) => state.newchat);
  const [file, setFile] = useState(null);

  const handleFileChangeold = (file) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (file) {
      console.log("**************files",file.type)
      if (file.type === 'application/pdf' || file.type === 'application/zip' || file.type === 'application/x-zip-compressed' || file.type ==="application/octet-stream") {
        setFile(file);
        dispatch(setSelectedNewchatFile(file));
        const userId = storedUser?._id;
        dispatch(processNewChatPdf({ file, userId }));
      } else {
        // Replace with a UI-friendly notification
        alert('Please select a PDF or ZIP file.');
      }
    }
  };

  const handleFileChange = (file) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (file) {
      console.log("**************files", file.type);
      const validTypes = [
        'application/pdf',
        'application/zip',
        'application/x-zip-compressed',
        'application/x-compressed-zip',
        'application/octet-stream'
      ];
      if (validTypes.includes(file.type) ||
          (file.type === 'application/octet-stream' &&
           (file.name.toLowerCase().endsWith('.pdf') || file.name.toLowerCase().endsWith('.zip')))) {
        setFile(file);
        dispatch(setSelectedNewchatFile(file));
        const userId = storedUser?._id;
        dispatch(processNewChatPdf({ file, userId }));
      } else {
        alert('Please select a PDF or ZIP file.');
      }
    }
  };
  
  
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      handleFileChange(acceptedFiles[0]);
    }
  }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: { 'application/pdf': ['.pdf'], 'application/zip': ['.zip'],'application/zip': ['.x-zip-compressed'] ,'application/zip':['.octet-stream'] }
  // });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/zip': ['.zip'],
      'application/x-zip-compressed': ['.zip'],
      'application/x-compressed-zip': ['.zip'],
      'application/octet-stream': ['.zip', '.pdf']
    }
  });

  useEffect(() => {
    if (responseNewChatData && responseNewChatData?.data?.jobId !== undefined) {
      navigate(`/threads/${responseNewChatData?.data?.jobId}`, { state: { request_id: responseNewChatData?.data?.jobId } });
    }
  }, [responseNewChatData, navigate]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <Box sx={{ position: 'relative', textAlign: 'center', padding: 4 }}>
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          top: "10px",
          left: "2px",
          fontWeight: 'bold',
          color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937',
        }}
      >
        New chat
      </Typography>

      {!loading && !responseNewChatData && (
        <Paper
          {...getRootProps()}
          sx={{
            border: '2px dashed',
            borderColor: isDragActive
              ? theme.palette.primary.main
              : theme.palette.mode === 'dark'
                ? '#616161'
                : '#E0E0E0',
            borderRadius: 4,
            marginTop: 10,
            padding: 4,
            textAlign: 'center',
            backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F9FAFB',
            transition: 'border-color 0.3s ease-in-out',
          }}
        >

          <input {...getInputProps()} />
          <Box
            sx={{
              width: 96,
              height: 96,
              backgroundColor: theme.palette.mode === 'dark' ? '#616161' : '#E0E0E0',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginX: 'auto',
              marginBottom: 2,
            }}
          >
            <Upload sx={{ fontSize: '48px', color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#9E9E9E' }} />
          </Box>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937' }}>
            {selectedNewChatFile ? 'Ready to analyze' : 'Drag & drop a file here or click to select'}
          </Typography>
          {selectedNewChatFile && (
            <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}>
              Selected file: {selectedNewChatFile.name}
            </Typography>
          )}
          {error && (
            <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
              {error?.error?.message}
            </Typography>
          )}
        </Paper>
      )}
      {loading && <SkeletonLoader />}
      {responseNewChatData && (
        <Chat wsdata={responseNewChatData} />
      )}
    </Box>
  );
};

export default NewChat;
