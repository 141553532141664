import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';

import { useWebSocket } from '../contexts/WebSocketContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { processPdf, setSelectedFile, reset } from '../redux/survey/pdfSlice';
import SkeletonLoader from '../components/SkeletonLoader';
import UploadIcon from '@mui/icons-material/Upload';
import { useTheme } from '@mui/material/styles';
import { fetchsearchesTabs } from '../redux/survey/uploadHistorySlice';
const SurveyAnalyzer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { selectedFile, responseData, loading, error } = useSelector((state) => state.pdf || {});
  const [pdfFiles, setPdfFiles] = useState([]);
  const [askQuestionsOpen, setAskQuestionsOpen] = useState(false);
  const [isChatScreenOpen, setIsChatScreenOpen] = useState(false);
  const { connectWebSocket, messages } = useWebSocket();
  const handleNewChat = () => {
    navigate('/');
  };

  const handleToggleChat = () => {
    setIsChatScreenOpen(!isChatScreenOpen);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const files = Array.from(acceptedFiles);

    if (files.length > 0) {
      const areAllPdfs = files.every((file) => file.type === 'application/pdf');
      if (areAllPdfs) {
        setPdfFiles(files);
        const userId = storedUser?._id;
        dispatch(processPdf({ files, userId, agent_name: 'survey' }));
      } else {
        alert('Please select only PDF files.');
      }
    }
  }, [dispatch]);

  useEffect(() => {
    // const storedUser = JSON.parse(localStorage.getItem('user'));
    if (responseData && responseData?.request_id !== undefined) {
      connectWebSocket(responseData?.request_id, responseData?.websocket_url);
      navigate(`/threads/${responseData?.request_id}`, { state: { url: responseData.websocket_url, request_id: responseData?.request_id } });
      // dispatch(fetchsearchesTabs(storedUser?._id));
    }
  }, [responseData, navigate]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    multiple: true, // Allow multiple files
  });

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <Box sx={{ position: 'relative', textAlign: 'center', padding: 4 }}>
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          top: '10px', // Adjust this value for the top position
          left: '2px',  // Adjust this value for left position
          fontWeight: 'bold',
          color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937',
        }}
      >
        Survey Analyzer
      </Typography>

      {!loading && !responseData && (
        <Paper
          {...getRootProps()}
          sx={{
            border: '2px dashed',
            borderColor: isDragActive
              ? theme.palette.primary.main
              : theme.palette.mode === 'dark'
                ? '#616161'
                : '#E0E0E0',
            borderRadius: 4,
            padding: 4,
            marginTop:10,
            textAlign: 'center',
            backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F9FAFB',
            transition: 'border-color 0.3s ease-in-out',
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              width: 96,
              height: 96,
              backgroundColor: theme.palette.mode === 'dark' ? '#616161' : '#E0E0E0',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginX: 'auto',
              marginBottom: 2,
            }}
          >
            <UploadIcon sx={{ fontSize: '48px', color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#9E9E9E' }} />
          </Box>
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', marginBottom: 1, color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937' }}
          >
            {isDragActive ? 'Drop the files here' : 'Drag, drop, or upload'}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}
          >
            {pdfFiles.length > 0 ? `Selected files: ${pdfFiles.map(file => file.name).join(', ')}` : 'Let us decode your images, one by one. Your insights will be carefully crafted and ready in less than 5 minutes!'}
          </Typography>
          {error && (
            <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}
          {/* <Button
            variant="contained"
            sx={{
              marginTop: 2,
              backgroundColor: theme.palette.mode === 'dark' ? '#1976D2' : '#3B82F6',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2196F3' : '#2563EB',
              },
            }}
          >
            Browse Files
          </Button> */}
        </Paper>
      )}

      {loading && <SkeletonLoader />}

      {responseData && (
        <>
          {/* Your component to display survey data */}
        </>
      )}

      {responseData && askQuestionsOpen && (
        <Button
          sx={{
            position: 'fixed',
            top: '250px',
            right: '0px',
            zIndex: 1000,
            backgroundColor: '#3f51b5',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#303f9f',
            },
          }}
          onClick={handleToggleChat}
        >
          {isChatScreenOpen ? 'Close Chat' : 'Open Chat'}
        </Button>
      )}
    </Box>
  );
};

export default SurveyAnalyzer;
