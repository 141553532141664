import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    IconButton,
    useTheme,
    Skeleton,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { processPlansetChat } from '../redux/survey/planSetAnalyzerSlice';
import SkeletonLoader from './SkeletonLoader';  // Import your SkeletonLoader component
import ReactMarkdown from 'react-markdown'; 
const PlansetChatScreen = ({ req_id }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const { plansetchatResponseData, chatloading } = useSelector((state) => state.planSetAnalyzer || {});
    const messagesEndRef = useRef(null);

    // Update messages when chatResponseData changes
    useEffect(() => {
        if (plansetchatResponseData) {
            setMessages(prevMessages => [
                ...prevMessages,
                { role: 'user', content: plansetchatResponseData.query },  // User question
                { role: 'assistant', content: plansetchatResponseData.answer, timestamp: plansetchatResponseData.conversationHistory[0]?.timestamp }  // Assistant response
            ]);
        }
    }, [plansetchatResponseData]);

    // Scroll to the latest message
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = () => {
        if (inputValue.trim() !== '') {
            dispatch(processPlansetChat({ query: inputValue, jobId: req_id }));
            setInputValue('');  // Clear input after sending
        }
    };

    const renderMessage = (message) => (
        <Box sx={{ mb: 2 }}>
            <Typography variant="body1" fontWeight="bold">
                {message.role === 'user' ? 'Q:' : 'A:'} <ReactMarkdown>{message.content}</ReactMarkdown>
            </Typography>
            {message.timestamp && (
                <Typography variant="caption" color="textSecondary">
                    {new Date(message.timestamp).toLocaleString()}
                </Typography>
            )}
        </Box>
    );

    return (
        <Box sx={{ paddingTop: 2, height: '80vh', display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.background.default, width: '100%' }}>
            <Paper elevation={3} sx={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', borderRadius: 2, backgroundColor: theme.palette.background.paper, width: '100%' }}>
                <List>
                    {messages.map((msg, index) => (
                        <ListItem alignItems="flex-start" key={index}>
                            <ListItemText primary={renderMessage(msg)} />
                        </ListItem>
                    ))}
                    {chatloading && (
                        <ListItem alignItems="flex-start">
                            <ListItemText primary={<SkeletonLoader />} />  {/* Display skeleton loader when loading */}
                        </ListItem>
                    )}
                </List>
                <div ref={messagesEndRef} />
            </Paper>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, borderRadius: '20px', backgroundColor: theme.palette.background.paper, padding: '2px', boxShadow: 3, position: 'relative', width: '100%', border: '1px solid', borderColor: theme.palette.divider }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Type your message..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                />
                <IconButton onClick={handleSendMessage}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default PlansetChatScreen;
