import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  Skeleton,
  useTheme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage, resetGenralChat } from '../redux/newchat/generalChatSlice';
import ReactMarkdown from 'react-markdown'; // For Markdown rendering

const ChatScreen = ({ wsdata }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { loading, response, error } = useSelector((state) => state.generalChat);
  const [messages, setMessages] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const messagesEndRef = useRef(null);

  const jobId = wsdata?.jobId;

  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  

  useEffect(() => {
    return () => {
      dispatch(resetGenralChat());
      setMessages([]);
    };
  }, [dispatch]);

  // Load WebSocket data into chat messages
  useEffect(() => {
    if (wsdata && wsdata?.data?.data?.chatOutputs !== undefined) {
      const data=wsdata.data.data.chatOutputs.map((output) => ({
        question: output.question,
        answer: output.final_answer?.answer || '',
        relevantImages: output.image_urls || [],
        sender: 'bot',
      }));
      setMessages(data);
    }
  }, [wsdata]);

  // Scroll to the bottom whenever messages are updated
  useEffect(scrollToBottom, [messages]);

  // Handle new responses from Redux
  useEffect(() => {
    if (response) {
      const botResponse = {
        question: response?.data?.question || 'No question provided',
        answer: response?.data?.answer || 'No answer provided',
        relevantImages: response?.data?.relevantImages || [],
        sender: 'bot',
      };
  
      setMessages((prevMessages) => {
        const existingMessageIndex = prevMessages.findIndex(
          (msg) => msg.sender === 'user' && msg.text === botResponse.question
        );
  
        if (existingMessageIndex !== -1) {
          // Update the existing user message with the bot's response
          const updatedMessages = [...prevMessages];
          updatedMessages[existingMessageIndex] = {
            ...updatedMessages[existingMessageIndex],
            answer: botResponse.answer,
            relevantImages: botResponse.relevantImages,
          };
          return updatedMessages;
        }
  
        // If the question is not found, add it as a new message
        return [...prevMessages, botResponse];
      });
  
      setIsLoadingResponse(false);
    }
  }, [response, jobId]);

  // Handle sending user messages
  const handleSendMessage = () => {
    if (inputValue.trim() !== '' && jobId) {
      const userMessage = { text: inputValue, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      setIsLoadingResponse(true);
      dispatch(sendMessage({ message: inputValue, jobId }));
      setInputValue('');
    }
  };

  return (
    <Box
      sx={{
        padding: 4,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          flex: 1,
          overflow: 'auto',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <List>
          {messages.map((msg, index) => (
            <React.Fragment key={index}>
              {msg.sender === 'user' && (
                <ListItem alignItems="flex-start" sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* Circle with "Q" */}
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.getContrastText(theme.palette.primary.main),
                      marginRight: 1,
                      flexShrink: 0,
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Q
                    </Typography>
                  </Box>

                  {/* Question Text */}
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color={theme.palette.text.primary}
                  >
                    {msg.text || msg.question}
                  </Typography>
                </ListItem>
              )}

              {msg.sender === 'bot' && (
                <ListItem alignItems="flex-start" sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* Circle with "Q" */}
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.getContrastText(theme.palette.secondary.main),
                      marginRight: 1,
                      flexShrink: 0,
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Q
                    </Typography>
                  </Box>

                  {/* Question Text */}
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color={theme.palette.text.primary}
                  >
                    {msg.question}
                  </Typography>
                </ListItem>
              )}

              {msg.answer && (
                <ListItem alignItems="flex-start">
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    <ReactMarkdown>{msg.answer}</ReactMarkdown>
                  </Typography>
                </ListItem>
              )}

              {msg.relevantImages && msg.relevantImages.length > 0 && (
                <ImageList cols={3} gap={8} sx={{ mt: 1 }}>
                  {msg.relevantImages.map((url, imgIndex) => (
                    <ImageListItem key={imgIndex}>
                      <img
                        src={url}
                        alt={`Relevant image ${imgIndex + 1}`}
                        loading="lazy"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}

              <Divider />
            </React.Fragment>
          ))}

          {isLoadingResponse && (
            <ListItem alignItems="flex-start">
              <Box width="100%">
                <Skeleton variant="text" height={20} width="40%" />
                <Skeleton variant="text" height={20} width="60%" />
                <Skeleton variant="rectangular" height={118} width="100%" />
              </Box>
            </ListItem>
          )}

          <div ref={messagesEndRef} />
        </List>
      </Paper>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
          borderRadius: '20px',
          backgroundColor: theme.palette.background.paper,
          padding: '10px',
          boxShadow: 3,
          position: 'relative',
          width: '100%',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Type your message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          sx={{
            borderRadius: '20px',
            marginLeft: 1,
            marginRight: 1,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}
          InputProps={{
            style: {
              color: theme.palette.text.primary,
            },
          }}
        />

        <IconButton
          onClick={handleSendMessage}
          sx={{
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatScreen;
