import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to call the chat message API
export const sendNecMessage = createAsyncThunk(
    'necChat/sendNecMessage',
    async ({ message, jobId, userId }, { rejectWithValue }) => {
        try {

            // const response = await axios.post('http://general-staging.framesense.ai/api/v1/chat/message', {
            const response = await axios.post(
                // 'https://general.framesense.ai/api/v1/chat/message'
                `http://206.189.168.227:3000/api/chat`
                , {
                    userId: userId,
                    query: message,
                    jobId,
                });

            return response.data; // return data to be used in the fulfilled state
        } catch (error) {
            // Return error data to be handled by the rejected state
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const necChatSlice = createSlice({
    name: 'necChat',
    initialState: {
        loading: false,
        response: null,
        error: null,
    },
    reducers: {
        // Optionally add additional reducers here if needed
        resetNecChat: (state) => {
            state.loading = null;
            state.response = null;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendNecMessage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendNecMessage.fulfilled, (state, action) => {
                state.loading = false;
                state.response = action.payload; // API response
                state.error = null;
            })
            .addCase(sendNecMessage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; // Error message from rejectWithValue
            });
    },
});

export const { resetNecChat } = necChatSlice.actions;
export default necChatSlice.reducer;
