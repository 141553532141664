import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const processSurveyChat = createAsyncThunk(
    'chat/processSurveyChat',
    async ({ userId,req_id }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_OPENAI_BASE_URL}/chat/init/`, { "request_id":req_id,"user_id": userId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const SurveyChatSlice = createSlice({
    name: 'surveychat',
    initialState: {
        chatResponseData: null,
        chatloading: false,
        chaterror: null,
    },
    reducers: {
        resetSurveyChat: (state) => {
            state.chatResponseData = null;
            state.chatloading = false;
            state.chaterror = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(processSurveyChat.pending, (state) => {
                state.chatloading = true;
            })
            .addCase(processSurveyChat.fulfilled, (state, action) => {
                state.chatloading = false;
                state.chatResponseData = action?.payload;
            })
            .addCase(processSurveyChat.rejected, (state, action) => {
                state.chatloading = false;
                state.chaterror = action?.payload;
            });
    },
});

export const { resetSurveyChat } = SurveyChatSlice.actions;

export default SurveyChatSlice.reducer;
